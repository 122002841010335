import React from 'react';
import CssStyle from './FullResume.module.css';
const FullResume = () => {
  return (
    <div className={CssStyle.FullResume}>
      <h1 className={CssStyle.FullResume}>Rany ElHousieny</h1>
    </div>
  );
};

export default FullResume;
