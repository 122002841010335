import React from 'react';
import FullResume from '../components/FullResume/FullResume';

const resume = () => {
  return (
    <div>
      <FullResume />
    </div>
  );
};

export default resume;
